import {BasicObject} from '@/types';
import {getDeviceCode} from '@/utils';
import {packageId} from '@/config';
import {trackEndponit} from '@/app.service';

const _events: any[] = [];
let timer: NodeJS.Timeout | null = null;
const REPORT_INTERVAL = 5000; // 5秒
const QUEUE_THRESHOLD = 20; // 队列阈值

export const trackRequest = (data: any[], sucCall: Function) => {
  // 请求接口
  trackEndponit(data)
    .then(() => {
      sucCall();
    })
    .catch((e: any) => {
      console.error('report failed', e);
    });
};

const sendEvents = () => {
  if (_events.length === 0) {
    return;
  }
  trackRequest(_events, () => {
    _events.length = 0;
    timer = null;
  });
};

function scheduleReport() {
  if (timer === null) {
    timer = setTimeout(() => {
      sendEvents();
    }, REPORT_INTERVAL);
  }
}

const properties: BasicObject = {
  $url_path: window?.location?.pathname || '',
};

const report = (event: 'expose' | 'click', data: BasicObject) => {
  const distinct_id = getDeviceCode(); // 设备ID
  properties.eventKey = data.event;
  properties.eventValue = data.event;

  const base = {
    type: 'track',
    distinct_id, // 设备ID
    project: packageId,
    event,
    time: Date.now(),
    properties,
  };
  const login_id = JSON.parse(localStorage.getItem('user') || '{}').userId; // 用户ID
  _events.push(
    login_id
      ? {
          ...base,
          anonymous_id: login_id,
          login_id: login_id,
        }
      : {
          ...base,
          anonymous_id: distinct_id,
          login_id: null,
        },
  );

  if (_events.length >= QUEUE_THRESHOLD) {
    // 如果队列长度达到阈值，立即发送并取消定时器
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }
    sendEvents();
  } else {
    // 否则，安排定时发送
    scheduleReport();
  }
};

// 确保在页面卸载时发送剩余的事件
if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', sendEvents);
}

export const trackExpose = (
  event: string, // 上报事件名
  reportInfo: BasicObject | null, // TODO: 上报详情，待确定
) => {
  report('expose', {event, ...reportInfo});
};

export const trackClick = (
  event: string, // 上报事件名
  reportInfo: BasicObject = {}, // TODO: 上报详情，待确定
) => {
  report('click', {event, ...reportInfo});
};
